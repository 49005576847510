<template>
  <div>
    <vue-qr :text="callBackInfo" :size="300" :logo-src="logoSrc"></vue-qr>
  </div>
</template>
<script>
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      logoSrc: require("../../assets/images/cl.png"),
    };
  },
  components: {
    vueQr,
  },
  computed: {
    ...mapState({
      callBackInfo: (state) => state.Pay.callbackInfo,
    }),
  },
  mounted() {
    console.log(this.callBackInfo);
  },
};
</script>

<style lang="scss"></style>
